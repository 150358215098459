import Vue from 'vue'

import {
  Container, Header, Main, Menu, MenuItem, Card, Image
} from 'element-ui'

const coms = [
  Container, Header, Main, Menu, MenuItem, Card, Image
]

coms.forEach(com => { Vue.component(com.name, com) })
