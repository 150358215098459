import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "index" */'@/views/layout/index'),
    redirect: 'home',
    children: [
      { path: 'home', name: 'home', component: () => import(/* webpackChunkName: "home" */'@/views/home/index') },
      { path: 'tech', name: 'tech', component: () => import(/* webpackChunkName: "tech" */'@/views/tech/index') },
      { path: 'product', name: 'product', component: () => import(/* webpackChunkName: "product" */'@/views/product/index') },
      { path: 'case', name: 'case', component: () => import(/* webpackChunkName: "case" */'@/views/case/index') },
      { path: 'contact', name: 'contact', component: () => import(/* webpackChunkName: "contact" */'@/views/contact/index') }
    ]
  },
  { path: '*', name: 'error', component: () => import(/* webpackChunkName: "error" */'@/views/error/index') }
]

const router = new VueRouter({
  routes
})

export default router
